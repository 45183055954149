<template>
  <div>
    <!-- If there is already an image saved -->
    <template v-if="item.Value.Name">
      <v-card elevation="0" max-width="700" class="mb-2">
        <v-card-text class="px-0">
          <v-img
            contain
            max-height="500"
            :src="
              serverUrl +
              '/images/' +
              websiteId +
              '/' +
              item.Value.Name +
              '?token=' +
              userToken
            "
          >
            <v-app-bar
              flat
              dense
              color="rgba(0, 0, 0, 0)"
              v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
            >
              <v-spacer></v-spacer>
              <v-btn
                depressed
                small
                color="primaryorange"
                icon
                @click="hover = !hover"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                depressed
                small
                color="primaryred"
                icon
                @click="deleteImage(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-app-bar>
          </v-img>
          <v-expand-transition>
            <v-layout v-if="hover" row wrap>
              <template
                v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
              >
                <v-layout
                  align-center
                  v-if="item.AddAltTags || item.AddAltTags == null"
                >
                  <v-select
                    v-if="item.Translate || item.Translate == undefined"
                    :items="itemLanguages"
                    v-model="itemDefaultLanguage"
                    single-line
                    class="altSelect-lang mt-2"
                    outlined
                    dense
                  ></v-select>
                  <template
                    v-for="(translatedValue, translatedValueIndex) in item.Value
                      .Alt"
                  >
                    <v-text-field
                      v-if="itemDefaultLanguage == translatedValueIndex"
                      :key="translatedValueIndex"
                      :class="
                        item.Translate || item.Translate == undefined
                          ? 'ml-4 mt-2'
                          : ' mt-2'
                      "
                      placeholder="Alt tag"
                      v-model="item.Value.Alt[translatedValueIndex]"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </template>
                </v-layout>
              </template>
              <template v-else>
                {{ item.Value.Alt[currentLanguage] }}
              </template>
            </v-layout>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </template>
    <!-- If no image was saved -->
    <template v-else-if="(!item.ReadOnly && !needsDisable) || editItemAdmin">
      <h3 v-if="file">
        <v-icon>mdi-image</v-icon>
        {{ file.name }}
        <v-btn depressed color="primaryred" icon @click="deleteSelectedImage()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </h3>
      <input
        :ref="'fileInput' + objectName"
        class="fileInput"
        type="file"
        @change="setSelectedFile($event.target.files)"
      />

      <v-btn
        depressed
        class="ml-0 mt-3 mr-3 edit-page-action-button"
        @click="$refs['fileInput' + objectName].click()"
        color="primary"
        >{{ $t("Choose an image") }}
      </v-btn>
      <v-btn
        depressed
        color="primaryblue"
        class="ml-0 mt-3 white--text edit-page-action-button"
        :disabled="!file || uploading || uploadError"
        :loading="uploading"
        @click="submitFile(item)"
      >
        {{ $t("Upload") }}
        <v-icon right dark>mdi-cloud-upload</v-icon>
      </v-btn>
    </template>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
            >
            </v-switch>
            <v-combobox
              v-if="item.FieldValidation"
              :items="$validation.getAllValidationNames()"
              class="float-left mr-3"
              :label="$t('Rule')"
              v-model="item.FieldValidationFuntion"
              clearable
              @click:clear="item.FieldValidationFuntion = ''"
              outlined
              dense
              hide-details="auto"
            >
            </v-combobox>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    uploadError: {},
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      serverUrl: this.$helpers.getServerUrl(),
      uploading: false,
      file: null,
      hover: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale();
    },
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    deleteImage(item) {
      item.Value.Name = "";
    },
    deleteSelectedImage() {
      this.file = null;
    },
    setSelectedFile(fileList) {
      this.$emit("setUploadError", "");
      this.file = fileList[0];
    },
    submitFile(item) {
      this.uploading = true;
      this.$emit("update:saving", true);
      this.$emit("setUploadError", "");

      let formData = new FormData();
      formData.append("files", this.file);
      this.$request.post(
        "/content/imageupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$emit("update:saving", false);
          this.$refs["fileInput" + this.objectName].value = null;
          if (res.success) {
            if (item.Value == "") {
              item.Value = {
                Name: "",
                Alt: {
                  Nl: "",
                  Fr: "",
                  En: "",
                },
              };
            }

            item.Value.Name = res.data[0];
            this.file = null;
          } else {
            this.$emit("setUploadError", res.message);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.imageContainer {
  position: relative;
  max-width: 100%;

  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}

.pageImage {
  max-width: 100%;
  max-height: 500px;
}
@media only screen and (max-width: 568px) {
  .edit-page-action-button {
    width: 100%;
  }

  .edit-page-action-button .btn__content {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 8px;
  }

  .edit-page-action-button .btn__content i {
    padding-top: 0px;
    margin-top: -3px;
    margin-left: 5px;
  }
}
</style>